/* Future Positive global styles */

* {
  box-sizing: border-box;
}

body {
  background: black;
  color: white;
  font-family: 'Atlas Grotesk LC', Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12em;
  text-transform: uppercase;
}
